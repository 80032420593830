import { QuestionLevel } from '@constants';

import BasicsIcon from './BasicsIcon';
import HotIcon from './HotIcon';
import PolemicsIcon from './PolemicsIcon';

export const iconsByLevel = {
  [QuestionLevel.BASICS]: BasicsIcon,
  [QuestionLevel.POLEMICS]: PolemicsIcon,
  [QuestionLevel.HOT]: HotIcon,
};

export type LevelIconProps = {
  level: QuestionLevel;
};

export default function LevelIcon({ level }: LevelIconProps) {
  const SelectedIcon = iconsByLevel[level];
  return <SelectedIcon />;
}

export function iconByLevel(level: QuestionLevel) {
  return iconsByLevel[level];
}
