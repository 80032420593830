import { Box, Button, Center, Flex, Heading, Icon } from '@chakra-ui/react';
import LevelIcon from '@components/icons/LevelIcon';
import ModalListItem from '@components/ModalListItem';
import { Paths, QuestionLevel } from '@constants';
import { useGame } from '@context/game';
import getStrings from '@strings';
import TruthOrDareAPI from '@utils/EuNuncaAPI';
import { levels } from '@utils/levels';
import { useEffect, useMemo } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

import './levels.scss';

const buttonIcons = {
  back: <Icon as={IoMdArrowRoundBack} />,
  play: <Icon as={IoPlay} />,
};

export default function Levels() {
  const { game, setGame } = useGame();
  const navigate = useNavigate();

  const strings = getStrings(game.lang);

  const startGame = () => {
    if (!game) {
      return;
    }

    if (!game.levels.length) {
      Swal.fire({
        text: strings.noLevelsAlert,
        icon: 'warning',
      });
      return;
    }

    navigate(Paths.PLAY);
  };

  useEffect(() => {
    (async () => {
      if (!game.id) {
        const newGame = await TruthOrDareAPI.createGame();
        setGame({ ...game, ...newGame });
      } else {
        try {
          const api = new TruthOrDareAPI(game);
          await api.getGame();
        } catch (err) {
          alert('lalala');
          setGame({ ...game, id: 0 });
        }
      }
    })();
  });

  const levelList = useMemo(() => levels(game.lang), [game.lang]);

  const toggleLevel = (level: QuestionLevel) => {
    const levels = [...game.levels];

    const levelIndex = levels.indexOf(level);

    if (levelIndex === -1) {
      levels.push(level);
    } else {
      levels.splice(levelIndex, 1);
    }

    setGame({ ...game, levels });
  };

  return (
    <>
      <Header />
      <Box className="playerList">
        <Center>
          <Heading className="playerTitle" bgColor="todGreen">
            {strings.levelSelector}
          </Heading>
        </Center>
        <Flex direction="column">
          {levelList.map((level, index) => {
            const { id, title, description } = level;
            const icon = <LevelIcon level={id} />;
            return (
              <ModalListItem
                title={title}
                description={description}
                icon={icon}
                isSelected={game.levels.includes(id)}
                onClick={() => toggleLevel(id)}
                key={`level-${index}`}
              />
            );
          })}
        </Flex>
        {/* <NoPlayers /> */}
        <Flex className="actionButtons">
          <Button
            bgColor="todRed"
            leftIcon={buttonIcons.back}
            onClick={() => navigate(Paths.HOME)}
            _hover={{
              bgColor: 'white',
              color: 'todRed',
            }}
          >
            {strings.back}
          </Button>
          <Button
            bgColor="todGreen"
            leftIcon={buttonIcons.play}
            onClick={startGame}
            _hover={{
              bgColor: 'white',
              color: 'todGreen',
            }}
          >
            {strings.startGame}
          </Button>
        </Flex>
      </Box>
      <Footer />
    </>
  );
}
