import { Flex, Text } from '@chakra-ui/react';
import getStrings, { Lang } from '@strings';
import { Question } from '@types';

type QuestionItemProps = {
  question: Question;
};

export default function QuestionItem(props: QuestionItemProps) {
  const { question } = props;

  const { content, comment, lang } = question;

  const strings = getStrings(lang as Lang);

  const placeholder = new RegExp(strings.neverHaveIEver, 'i');

  const text = content.replace(placeholder, '');
  return (
    <Flex direction="column">
      <Flex
        minW={0}
        borderColor="rgba(255, 255, 255, 0.5)"
        border="1px solid"
        height="auto"
        width="100%"
        padding="1.25rem"
        direction="column"
      >
        <Text fontSize="2.2rem" as="span">
          {text}
        </Text>
        {comment ? (
          <Text
            textAlign="center"
            fontSize="1.2rem"
            width="100%"
            paddingTop="2.2rem"
          >
            {question.comment}
          </Text>
        ) : undefined}
      </Flex>
    </Flex>
  );
}
