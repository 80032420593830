import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Icon,
} from '@chakra-ui/react';
import QuestionItem from '@components/Question';
import { Paths } from '@constants';
import { useGame } from '@context/game';
import getStrings from '@strings';
import { Level, Question } from '@types';
import EuNuncaAPI from '@utils/EuNuncaAPI';
import { levels } from '@utils/levels';
import { useEffect, useMemo, useState } from 'react';
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const buttonIcons = {
  back: <Icon as={IoMdArrowRoundBack} />,
  play: <Icon as={IoMdArrowRoundForward} />,
};

export default function Play() {
  const { game } = useGame();
  const navigate = useNavigate();
  const levelList = useMemo(() => levels(game.lang), [game.lang]);
  const [question, setQuestion] = useState<Question | null>(null);
  const api = new EuNuncaAPI(game);
  const levelsById = useMemo(
    () =>
      levels(game.lang).reduce((acc: Record<string, Level>, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {}),
    [levelList],
  );

  const strings = getStrings(game.lang);

  useEffect(() => {
    if (!question) {
      api
        .getQuestion(game.levels)
        .then((newQuestion) => setQuestion(newQuestion));
    }
  }, [question]);

  return (
    <>
      <Header />
      <Box className="playerList" width="100%">
        <Center>
          <Heading fontSize="2.5rem" className="playerTitle" bgColor="todRed">
            {strings.neverHaveIEver}
          </Heading>
        </Center>
        <Flex direction="column">
          {question ? (
            <QuestionItem question={question} />
          ) : (
            <Button isLoading variant="ghost" width="100%" height="60px" />
          )}
        </Flex>
        <ButtonGroup
          width="100%"
          justifyContent="space-between"
          padding="15px 20px"
          position="relative"
        >
          <Button
            bgColor="todRed"
            leftIcon={buttonIcons.back}
            onClick={() => navigate(Paths.LEVELS)}
            _hover={{
              background: 'white',
              color: 'todRed',
            }}
          >
            {strings.levels}
          </Button>
          {question && (
            <Button
              variant="ghost"
              width="60%"
              disabled={true}
              _disabled={{ textColor: 'white' }}
            >
              {levelsById[question.level].title}
            </Button>
          )}
          <Button
            bgColor="todGreen"
            leftIcon={buttonIcons.play}
            isLoading={!question}
            onClick={() => setQuestion(null)}
            _hover={{
              background: 'white',
              color: 'todGreen',
            }}
          >
            {strings.next}
          </Button>
        </ButtonGroup>
      </Box>
      <Footer />
    </>
  );
}
