import { EMPTY_GAME } from '@constants';
import { Game } from '@types';
import { createContext, useContext } from 'react';

export type GameContextType = {
  game: Game;
  setGame: (game: Game) => void;
};

export const GameContext = createContext<GameContextType>({
  game: EMPTY_GAME,
  setGame: () => console.warn('no provider'),
});

export const useGame = () => useContext(GameContext);
