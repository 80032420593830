import { Box, ButtonGroup, Icon, IconButton } from '@chakra-ui/react';
import { EMPTY_GAME, Paths } from '@constants';
import { useGame } from '@context/game';
import ExitIcon from '@icons/ExitIcon';
import getStrings from '@strings';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';

export default function HeaderActions() {
  const { game, setGame } = useGame();
  const navigate = useNavigate();

  const strings = getStrings(game.lang);

  const quitGame = () => {
    if (!game) return;

    Swal.fire({
      icon: 'question',
      title: strings.exitGameQuestion,
      text: strings.exitGameDescription,
      showDenyButton: true,
      confirmButtonText: strings.exitNo,
      denyButtonText: strings.exitYes,
    }).then((result) => {
      const { isDenied } = result;

      if (isDenied) {
        setGame(EMPTY_GAME);
        navigate(Paths.HOME);
      }
    });
  };

  return (
    <>
      <Box
        display={{ base: 'block', md: 'block' }}
        className="header-actions-container"
        mt={{ base: 4, md: 0 }}
      >
        <ButtonGroup display="flex">
          <IconButton
            aria-label="Exit"
            isRound={true}
            icon={<Icon as={ExitIcon}></Icon>}
            maxHeight="60px"
            className="headerAction"
            variant="link"
            onClick={() => quitGame()}
          />
        </ButtonGroup>
      </Box>
    </>
  );
}
