export default function BasicsIcon() {
  return (
    <svg
      width="447"
      height="340"
      viewBox="0 0 447 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M422.992 68.9248H137.161V248.788C137.161 261.876 147.774 272.488 160.861 272.488H306.906L404.317 338.074C408.704 341.032 414.553 340.533 418.376 336.889C422.208 333.246 422.984 327.429 420.246 322.903L389.741 272.488H422.991C436.08 272.488 446.691 261.877 446.691 248.788V92.6248C446.692 79.5378 436.079 68.9248 422.992 68.9248Z"
        fill="#FF7F1F"
      />
      <path
        opacity="0.1"
        d="M333.197 44.452C333.197 31.363 322.586 20.752 309.497 20.752H47.367C34.278 20.752 23.667 31.363 23.667 44.452V200.615C23.667 213.704 34.278 224.315 47.367 224.315H80.618L60.852 256.983L50.296 274.429C47.852 278.468 47.921 283.675 50.806 287.41C54.516 292.212 61.178 293.176 66.041 289.903L114.934 256.983L163.453 224.315H292.29C314.883 224.315 333.198 206 333.198 183.407V44.452H333.197Z"
        fill="black"
      />
      <path
        d="M309.531 24.3943C309.531 11.3053 298.92 0.694336 285.831 0.694336H23.7C10.611 0.694336 0 11.3053 0 24.3943V180.556C0 193.645 10.611 204.256 23.7 204.256H56.951L37.185 236.924L26.447 254.671C23.711 259.193 24.487 265.01 28.315 268.656C32.142 272.302 37.99 272.796 42.374 269.844L91.267 236.924L139.786 204.256H285.83C298.919 204.256 309.53 193.645 309.53 180.556L309.531 24.3943Z"
        fill="#E5394B"
      />
      <path
        d="M99.4891 124.307C108.926 124.307 116.576 116.657 116.576 107.22C116.576 97.7829 108.926 90.1328 99.4891 90.1328C90.0522 90.1328 82.4021 97.7829 82.4021 107.22C82.4021 116.657 90.0522 124.307 99.4891 124.307Z"
        fill="#FCB44D"
      />
      <path
        d="M154.248 124.307C163.685 124.307 171.335 116.657 171.335 107.22C171.335 97.7829 163.685 90.1328 154.248 90.1328C144.811 90.1328 137.161 97.7829 137.161 107.22C137.161 116.657 144.811 124.307 154.248 124.307Z"
        fill="#FCB44D"
      />
      <path
        d="M209.007 124.307C218.444 124.307 226.094 116.657 226.094 107.22C226.094 97.7829 218.444 90.1328 209.007 90.1328C199.57 90.1328 191.92 97.7829 191.92 107.22C191.92 116.657 199.57 124.307 209.007 124.307Z"
        fill="#FCB44D"
      />
    </svg>
  );
}
