import { Box, Flex, Image } from '@chakra-ui/react';
import HeaderActions from '@components/HeaderActions';
import { Paths } from '@constants';
import logo from '@img/logo.svg';
import { useNavigate } from 'react-router';

import './header.scss';

export default function Header() {
  const navigate = useNavigate();
  return (
    <Flex className="header" bgColor="todGreen">
      <Box onClick={() => navigate(Paths.HOME)}>
        <Image className="hLogo" src={logo} />
      </Box>
      <HeaderActions />
    </Flex>
  );
}
