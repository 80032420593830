const langDefPtBr = {
  footerPrefix: 'Esse jogo é desenvolvido com muito',
  and: 'e',
  by: 'por',
  howToPlay: 'Como jogar',
  howToPlaySteps: [
    'Escolha pelo menos um nível para as afirmações',
    'Pode ativar todos os níveis ao mesmo tempo, se quiser',
    'Inicie o jogo',
    'Todas as afirmações começam com EU NUNCA',
    'Ao aparecer a afirmação, leia em voz alta para todos',
    'Quem já fez o que a afirmação diz, perde',
    'Se estiver jogando como um Drinking Game, quem perdeu bebe 🍺',
  ],
  startGame: 'Jogar!',
  levelSelector: 'Selecione os Níveis',
  back: 'Voltar',
  next: 'Próxima',
  levels: 'Níveis',
  levelBasics: 'O Básico',
  basicsDescription: 'As básicas, sem muita polêmica. Ideal para esquentar',
  levelPolemics: 'Polêmicas',
  polemicsDescription:
    'Aquelas famosas polêmicas de um bom Eu Nunca. Perfeitas pra um jogo com os amigos',
  levelHot: 'Hot 🔥',
  hotDescription:
    'Quer dar aquela esquentada no clima ou fazer uma baguncinha? Eu tenho o que você quer bem aqui 😏',
  neverHaveIEver: 'Eu nunca',
  noLevelsAlert: 'Escolha pelo menos um nível para jogar',
  exitGameQuestion: 'Quer realmente sair do jogo?',
  exitGameDescription:
    'Todas as afirmações já vistas ficarão disponíveis de novo',
  exitYes: 'Sim, quero sair :/',
  exitNo: 'Não, vamos jogar :D',
};

export default langDefPtBr;
