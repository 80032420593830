import { Link, Text } from '@chakra-ui/react';
import { useGame } from '@context/game';
import getStrings from '@strings';

import Emoji from '../Emoji';

import './footer.scss';

export default function Footer() {
  const { game } = useGame();
  const strings = getStrings(game.lang);
  return (
    <footer>
      <Text size="15px" color="white">
        {strings.footerPrefix}
        <Emoji label="love" symbol="❤️" />
        {strings.and}
        <Emoji label="coffe" symbol="☕" />
        {strings.by + ' '}
        <Link
          href="https://www.alexsander.ml"
          color="white"
          fontWeight={'bold'}
          isExternal
        >
          Alê Lira
        </Link>
        !
      </Text>
    </footer>
  );
}
