import { DEFAULT_LANG } from '@constants';

import PtBrLang from './pt-br';

const langs = {
  'pt-br': PtBrLang,
};

export type Lang = keyof typeof langs;

export default function getStrings(lang?: Lang) {
  return langs[lang || DEFAULT_LANG];
}
