import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react';
import { Paths } from '@constants';
import { useGame } from '@context/game';
import logo from '@img/logo.svg';
import getStrings from '@strings';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import './home.scss';
import Footer from '../../components/Footer';

export default function Home() {
  const { game } = useGame();
  const navigate = useNavigate();
  const buttonIcon = <Icon as={IoPlay} />;

  const strings = getStrings(game.lang);

  const goToLevelsPage = () => navigate(Paths.LEVELS);

  return (
    <>
      <Flex maxW="1140px" direction="column" className="home">
        <Center>
          <SimpleGrid columns={{ sm: 1, md: 2 }}>
            <Box className="mainLogo">
              <Image src={logo} />
            </Box>
            <Box marginTop="55px">
              <Center>
                <Heading>{strings.howToPlay}</Heading>
              </Center>
              <List className="rules">
                {strings.howToPlaySteps.map((step, index) => (
                  <ListItem className="rule" key={`rule-${index}`}>
                    {step}{' '}
                  </ListItem>
                ))}
              </List>
              <Button
                bgColor="todRed"
                variant="solid"
                rightIcon={buttonIcon}
                float="right"
                margin="50px"
                onClick={goToLevelsPage}
                onTouchEnd={goToLevelsPage}
                _hover={{
                  bgColor: 'white',
                  color: 'todRed',
                }}
              >
                {strings.startGame}
              </Button>
            </Box>
          </SimpleGrid>
        </Center>
      </Flex>
      <Footer />
    </>
  );
}
