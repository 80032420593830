import { Paths } from '@constants';
import Home from '@pages/Home';
import Levels from '@pages/Levels';
import Play from '@pages/Play';
import { BrowserRouter, Routes as Router, Route } from 'react-router-dom';

export default function Routes() {
  return (
    <BrowserRouter>
      <Router>
        <Route path={Paths.HOME} element={<Home />} />
        <Route path={Paths.LEVELS} element={<Levels />} />
        <Route path={Paths.PLAY} element={<Play />} />
        {/* <Route exact path="/players">
            <Page><PlayerList /></Page>
          </Route>
          <Route exact path="/players/:id" component={PlayerEdit}/>
          <Route exact path="/game" component={Game}/> */}
        {/* 
        <Route exact path="/names">
          <Names numberOfPlayers={this.state.numberOfPlayers} setNames={this.setPlayersNames} />
        </Route>
        <Route exact path="/game">
          <Game names={this.state.names} numberOfPlayers={this.state.numberOfPlayers} />
        </Route> */}
      </Router>
    </BrowserRouter>
  );
}
