import { Avatar, Flex, Heading, Text, Icon } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im';

type ModalListItemProps = {
  title: string;
  description: string;
  icon: ReactElement;
  isSelected: boolean;
  onClick: () => void;
};

export default function ModalListItem(props: ModalListItemProps) {
  const { title, description, icon, isSelected, onClick } = props;
  return (
    <Flex
      as="button"
      borderColor="rgba(255, 255, 255, 0.5)"
      borderBottom="1px solid"
      height="auto"
      padding="1.25rem"
      onClick={() => onClick()}
      _first={{ borderTop: '1px solid' }}
    >
      <Flex minW={0}>
        <Avatar
          icon={icon}
          bg={'todBlack'}
          alt={`${title} icon`}
          mr={{ base: 2, lg: 4 }}
          minHeight="100%"
          size="lg"
        />
        <Flex direction="column" overflow="hidden">
          <Heading
            as="h6"
            pos="relative"
            minW={0}
            overflow="hidden"
            whiteSpace="break-spaces"
            textOverflow="ellipsis"
            className={`playerName`}
            color={isSelected ? 'todRed' : 'todGreen'}
            textAlign="left"
            marginBottom="5px"
            fontSize="1.5rem"
          >
            {title}
          </Heading>
          <Text
            color="textSecondary"
            fontSize="md"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="left"
          >
            {description}
          </Text>
        </Flex>
      </Flex>
      <Flex
        shrink={0}
        basis="20%"
        mt={1}
        ml="auto"
        color="textSecondary"
        fontSize="xl"
        justifyContent="center"
        direction="column"
      >
        <Flex direction="row" justifyContent="center">
          <Icon
            color={isSelected ? 'todRed' : 'todGreen'}
            margin="5px 5px 5px 5"
            fontSize="1.5rem"
            as={isSelected ? ImCheckboxChecked : ImCheckboxUnchecked}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
