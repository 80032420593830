import { Game } from '@types';

export const colors = {
  green: '#008080',
  red: '#cc5151',
  black: '#1f1f1f',
  white: '#e5e5e5',
};

export enum Levels {
  ICE_BREAKER = 1,
  MILD = 2,
  WARM = 3,
  HOT = 4,
  VERY_HOT = 5,
  EXTREMELY_HOT = 6,
  INSANELY_HOT = 7,
  HOT_AS_FUCK = 8,
  FINISH_LINE = 9,
}

export const DEFAULT_LANG = 'pt-br';
export const EMPTY_GAME: Game = {
  id: 0,
  lang: DEFAULT_LANG,
  token: '',
  levels: [],
};

export enum QuestionLevel {
  BASICS = 'BASICS',
  POLEMICS = 'POLEMICS',
  HOT = 'HOT',
}

export enum QuestionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REPROVED = 'REPROVED',
}

export enum Paths {
  HOME = '/',
  LEVELS = '/levels',
  PLAY = '/play',
}
