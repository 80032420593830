import { QuestionLevel } from '@constants';
import { Game, Question } from '@types';
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import RequestException from './RequestException';

const baseURL = <string>process.env.REACT_APP_API_HOST;

export default class EuNuncaAPI {
  private connector: AxiosInstance;
  private gameId: number;
  private token: string;

  constructor({ id, token }: Game) {
    this.gameId = id;
    this.token = token;

    const connector = Axios.create({
      baseURL: `${baseURL}/game`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      validateStatus: () => true,
    });

    connector.interceptors.response.use(
      (response) => response,
      (error) => {
        this.handleAxiosError(error);
      },
    );

    this.connector = connector;
  }

  private handleAxiosError(error: AxiosError) {
    let errorMessage = 'An error ocurred while setting up the API request';
    let statusCode = 0;
    if (error.response) {
      errorMessage = error.response.data.message;
      statusCode = error.response.status;
    } else if (error.request) {
      errorMessage = 'No response was received from API';
    }

    throw new RequestException(errorMessage, statusCode);
  }

  static async createGame(lang = 'pt-br') {
    const url = `${baseURL}/game`;

    try {
      const { data: game } = await Axios.post<Game>(url, { lang });

      return game;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateLevel(level: QuestionLevel) {
    const response = await this.connector.put<Game>(`/`, { level });

    return this.validateResponse<Game>(response);
  }

  async getGame() {
    const response = await this.connector.get<Game>(`/`);

    return this.validateResponse<Game>(response);
  }

  async resetGame() {
    const response = await this.connector.post<Game>('/reset');

    const { game, players } = this.validateResponse(response);

    game.players = players;

    return game as Game;
  }

  async getQuestion(gameLevels: QuestionLevel[]) {
    const levels = gameLevels.join(';');
    const response = await this.connector.get<Question>(`/question`, {
      params: { levels },
    });

    return this.validateResponse<Question>(response);
  }

  private validateResponse<T>(response: AxiosResponse): T {
    if (response.status > 199 && response.status < 300) {
      return response.data;
    }

    const error: any = new Error(
      'An error occurred while communicating with Eu Nunca Core',
    );
    error.response = response;

    console.log('RES status:', response.status);
    console.log('RES data:', response.data);

    throw error;
  }
}
