import { QuestionLevel } from '@constants';
import getStrings, { Lang } from '@strings';
import { Level } from '@types';

export function levels(lang: Lang): Level[] {
  const strings = getStrings(lang);

  return [
    {
      id: QuestionLevel.BASICS,
      title: strings.levelBasics,
      description: strings.basicsDescription,
    },
    {
      id: QuestionLevel.POLEMICS,
      title: strings.levelPolemics,
      description: strings.polemicsDescription,
    },
    {
      id: QuestionLevel.HOT,
      title: strings.levelHot,
      description: strings.hotDescription,
    },
  ];
}
