import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { colors, EMPTY_GAME } from '@constants';
import { GameContext } from '@context/game';
import { Game } from '@types';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import 'velocity-animate/velocity';
import 'velocity-animate/velocity.ui';
import './App.scss';

import Routes from './Routes';

const theme = extendTheme({
  colors: {
    todGreen: colors.green,
    todRed: colors.red,
    todBlack: colors.black,
    todWhite: colors.white,
  },
});

function App() {
  const [storeGame, setStoreGame] = useLocalStorage<Game>(`@EuNunca:Game`);

  const [game, setGame] = useState<Game>(storeGame || EMPTY_GAME);

  const updateGame = (updatedGame: Game) => {
    setStoreGame(updatedGame);
    setGame(updatedGame);
  };

  return (
    <GameContext.Provider value={{ game, setGame: updateGame }}>
      <ChakraProvider theme={theme}>
        <div className="App">
          <Routes />
        </div>
      </ChakraProvider>
    </GameContext.Provider>
  );
}

export default App;
